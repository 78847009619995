import { Box, Typography } from "@mui/material";
import { GridSlotProps, GridToolbarContainer } from "@mui/x-data-grid";

declare module "@mui/x-data-grid" {
  interface ToolbarPropsOverrides {
    countMsg: string;
    numSelected: number;
    testModeToggle?: JSX.Element;
    defaultBtns?: JSX.Element;
    selectedBtns?: JSX.Element;
  }
}

export default function DatagridToolbar({
  countMsg,
  numSelected,
  testModeToggle,
  defaultBtns,
  selectedBtns,
}: GridSlotProps["toolbar"]) {
  return (
    <>
      <GridToolbarContainer
        sx={{
          backgroundColor: "background.drawer",
          paddingBottom: "5px",
          minHeight: "63px",
          display: "flex",
        }}
      >
        <Box>
          {numSelected <= 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {defaultBtns}
              <Typography data-cy="num-objects" sx={{ margin: "15px" }}>
                {countMsg}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {selectedBtns}
              <Typography
                data-cy="num-objects-selected"
                sx={{ margin: "15px" }}
              >
                {numSelected} selected
              </Typography>
            </Box>
          )}
        </Box>
        <Box flexGrow={1} />
        <Box>{testModeToggle}</Box>
      </GridToolbarContainer>
    </>
  );
}
