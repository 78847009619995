import { useCallback, useEffect, useRef, useState } from "react";
import Dashboard, { DashboardMetricData } from "../../model/Dashboard";
import useFeature from "../../hooks/useFeature";
import {
  PageContainer,
  PageContent,
  PageHeader,
} from "../../components/layout/page";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Grid,
  Tooltip,
} from "@mui/material";
import DashboardContext from "./hooks/DashboardContext";
import DashboardDatePicker from "./components/DashboardDatePicker";
import React from "react";
import { DashboardElementRenderer } from "./components/DashboardElementRenderer";
import { UpdateDashboardDialog } from "./components/dialogs/UpdateDashboardDialog";
import { FileDownload } from "@mui/icons-material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { SubmitButton } from "../../components/elements";
import { MetricsAPI } from "../../api";

type DashboardPageProps = {
  dashboard: Dashboard;
  setDashboard: (newDashboard: Dashboard) => void;
};

export const DashboardPage = ({
  dashboard: currentDashboard,
  setDashboard,
}: DashboardPageProps) => {
  const canEditDashboard = useFeature("editDashboard");
  const [dateRange, setDateRange] = useState<{ start?: Date; end?: Date }>({});
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [dashboardMetrics, setDashboardMetrics] =
    useState<DashboardMetricData>();

  const pdfRef = useRef();

  const exportPDF = useCallback(async () => {
    const margin = 15;
    const element = pdfRef.current;
    if (!element) return;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth() - margin * 2;
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    const date = new Date().toLocaleDateString();

    pdf.addImage(data, "PNG", margin, margin, pdfWidth, pdfHeight);
    pdf.save((currentDashboard?.config?.name ?? "Dashboard") + `-${date}`);
  }, [currentDashboard?.config?.name, pdfRef]);

  useEffect(() => {
    const defaultRange = currentDashboard.config?.defaultDateRange;
    if (
      !defaultRange ||
      (!defaultRange.start.year && !defaultRange.start.month) ||
      (!defaultRange.end.year && !defaultRange.end.month)
    ) {
      setDateRange({});
      return;
    }

    const currentDate = new Date();

    let startDate: Date | undefined = undefined;
    let endDate: Date | undefined = undefined;

    // if both start and end have a year and a date, use it
    if (
      defaultRange.start.year &&
      defaultRange.start.month &&
      defaultRange.end.year &&
      defaultRange.end.month
    ) {
      startDate = new Date(defaultRange.start.year, defaultRange.start.month);
      endDate = new Date(defaultRange.end.year, defaultRange.end.month);
    }
    // if both have a month
    else if (defaultRange.start.month && defaultRange.end.month) {
      // if the start month is after the current month, it should start in the previous year
      if (defaultRange.start.month >= currentDate.getMonth()) {
        startDate = new Date(
          currentDate.getFullYear() - 1,
          defaultRange.start.month - 1,
        );
      } else {
        startDate = new Date(
          currentDate.getFullYear(),
          defaultRange.start.month - 1,
        );
      }
      // if the start month is after the end month, the end date should be the year after the start date
      if (defaultRange.start.month > defaultRange.end.month) {
        endDate = new Date(
          startDate.getFullYear() + 1,
          defaultRange.end.month - 1,
        );
      }
      // if the start month and end month are equal, the end date should be the last day of the month before
      else if (defaultRange.start.month == defaultRange.end.month) {
        endDate = new Date(
          startDate.getFullYear() + 1,
          defaultRange.end.month - 1,
          0,
        );
      }
      // otherwise, end the same year
      else {
        endDate = new Date(
          currentDate.getFullYear(),
          defaultRange.end.month - 1,
        );
      }
    }

    if (!startDate || !endDate || startDate.valueOf() > endDate.valueOf()) {
      setDateRange({});
      return;
    }

    setDateRange({ start: startDate, end: endDate });
  }, [currentDashboard]);

  useEffect(() => {
    MetricsAPI.getAllMetricsForDashboard(
      currentDashboard.id,
      dateRange.start,
      dateRange.end,
    ).then((response) => {
      setDashboardMetrics(response);
    });
  }, [currentDashboard.id, dateRange.end, dateRange.start]);

  const PageHeaderActions = useCallback<() => JSX.Element>(() => {
    return (
      <ButtonGroup>
        {canEditDashboard && (
          <Button
            onClick={() => {
              setOpenUpdateDialog(true);
            }}
            variant="contained"
          >
            {"Update Config"}
          </Button>
        )}
        <Tooltip title={"Export dashboard as PDF"}>
          <SubmitButton
            onSubmit={() => exportPDF()}
            variant="outlined"
            startIcon={<FileDownload />}
          >
            {"PDF"}
          </SubmitButton>
        </Tooltip>
      </ButtonGroup>
    );
  }, [canEditDashboard, exportPDF]);

  return (
    <PageContainer variant="full">
      <PageHeader
        actions={<PageHeaderActions />}
        title={currentDashboard?.config?.name ?? "Dashboard"}
      />
      <Divider variant="middle" />
      {!currentDashboard.config?.hideDatePicker && (
        <DashboardDatePicker
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      )}
      <PageContent>
        <Container
          maxWidth="lg"
          sx={{
            pb: 5,
            pt: currentDashboard.config?.hideDatePicker ? 2 : 0,
          }}
        >
          <Box ref={pdfRef}>
            <DashboardContext.Provider
              value={{
                currentDashboard,
                dateRange,
              }}
            >
              <Grid container gap={2}>
                {currentDashboard.config?.elements.map(
                  (elementRow, rowIndex) => {
                    try {
                      return (
                        <Grid
                          key={rowIndex}
                          container
                          flexDirection="row"
                          gap={2}
                        >
                          {elementRow?.map((element, colIndex) => {
                            try {
                              return (
                                <Grid
                                  key={colIndex}
                                  item
                                  sx={{
                                    flex: {
                                      lg:
                                        element.type == "header" &&
                                        element.elements
                                          ? element.elements.length
                                          : 1,
                                      xs: 1,
                                    },
                                  }}
                                >
                                  <DashboardElementRenderer
                                    {...element}
                                    data={
                                      dashboardMetrics?.[rowIndex][colIndex]
                                    }
                                  />
                                </Grid>
                              );
                            } catch (e) {
                              console.error(e);
                              return (
                                <React.Fragment key={colIndex}></React.Fragment>
                              );
                            }
                          })}
                        </Grid>
                      );
                    } catch (e) {
                      console.error(e);
                      return <React.Fragment key={rowIndex}></React.Fragment>;
                    }
                  },
                )}
              </Grid>
            </DashboardContext.Provider>
          </Box>
        </Container>
      </PageContent>
      <UpdateDashboardDialog
        open={openUpdateDialog}
        handleConfirm={(newDashboard) => {
          setOpenUpdateDialog(false);
          setDashboard(newDashboard);
        }}
        handleCancel={() => {
          setOpenUpdateDialog(false);
        }}
        dashboard={currentDashboard}
      />
    </PageContainer>
  );
};
