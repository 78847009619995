import { useCallback, useEffect, useState } from "react";
import Users from "../../../api/users";
import { Role, User } from "../../../model";
import {
  GlobalPageContext,
  Display,
} from "../../../hooks/useGlobalPageContext";
import Roles from "edo-platform-frontend/src/api/roles";
import { GlobalUserContext } from "../../../hooks/useGlobalUserContext";
import { useAlert } from "../../../lib/alert";
import { currentUserId } from "../../../lib/auth";
import { CommonRole } from "../../../types";
import { GlobalRoleContext } from "../../../hooks/useGlobalRoleContext";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { useDisplayOptions } from "../../../hooks/useDisplayOptions";
import { environment } from "../../../util";
import { AccountTypeAPI } from "../../../api";
import DashboardLayout from "./DashboardLayout";

const Dashboard = (props: React.PropsWithChildren<unknown>) => {
  // hooks
  const alertContext = useAlert();
  const globalOrganizationContext = useGlobalOrganizationContext();

  // state
  const [user, setUser] = useState<User>(new User());
  const [display, setDisplay] = useState<Display>({ drawer: true });
  const [roles, setRoles] = useState<Role[]>([]);
  const [accountRoles, setAccountRoles] = useState<Role[]>([]);

  // spread hooks
  const { handleRejectionWithWarning } = alertContext;
  const { organization, accountListIsMemberOf } = globalOrganizationContext;

  const getAccountRoles = useCallback(async () => {
    const accRoles = accountListIsMemberOf
      .filter((account) => !!account.account_type_id)
      .map(async (account) => {
        if (!account.account_type_id) return [];
        const accountType = await AccountTypeAPI.get(account.account_type_id);
        if (!accountType.role_id) return [];
        const role = new Role();
        role.id = accountType.role_id;
        role.name = accountType.name;
        return role;
      });

    return (await Promise.all(accRoles)).flat();
  }, [accountListIsMemberOf]);

  useEffect(() => {
    Users.get(currentUserId()).then(
      setUser,
      handleRejectionWithWarning("Could not get current user"),
    );
  }, [handleRejectionWithWarning]);

  useEffect(() => {
    try {
      const getRoles = async () => {
        let roles: Role[] = [];
        const publicRole = new Role();
        publicRole.id = CommonRole.PUBLIC;
        const accountRoles = await getAccountRoles();
        if (organization) {
          roles = await Roles.byUserIdAndOrganizationId(
            currentUserId(),
            organization.id,
          );
        }
        setRoles([...roles, publicRole]);
        setAccountRoles(accountRoles);
      };
      getRoles();
    } catch {
      handleRejectionWithWarning("Could not get current roles");
    }
  }, [getAccountRoles, handleRejectionWithWarning, organization]);

  useDisplayOptions({
    displayIcon: organization?.theme?.faviconUrl ?? environment.app?.logoIcon,
  });

  return (
    <GlobalUserContext.Provider value={{ user, setUser }}>
      <GlobalRoleContext.Provider
        value={{ roles, setRoles, accountRoles, setAccountRoles }}
      >
        <GlobalPageContext.Provider value={{ display, setDisplay }}>
          <DashboardLayout>{props.children}</DashboardLayout>
        </GlobalPageContext.Provider>
      </GlobalRoleContext.Provider>
    </GlobalUserContext.Provider>
  );
};

export default Dashboard;
