import { FC, useCallback, useEffect, useState } from "react";
import { CommonDatagridWrapper } from "../../../components/elements";
import {
  GridRowSelectionModel,
  GridRenderCellParams,
  GridColDef,
} from "@mui/x-data-grid";
import { useAlert } from "../../../lib/alert";
import { AccountType } from "../../../model/AccountType";
import { AccountTypeAPI } from "../../../api";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import StyledDataGrid from "../../../components/elements/datagrid/StyledDataGrid";

type AccountTypeListProps = {
  organizationId: string;
};

const AccountTypeList: FC<AccountTypeListProps> = (props) => {
  const navigate = useNavigate();
  const { handleRejectionWithError } = useAlert();

  const [isLoaded, setIsLoaded] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  );
  const [accountTypes, setAccountTypes] = useState<Array<AccountType>>([]);

  const accountTypeColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "edit_action",
      type: "actions",
      width: 200,
      headerName: "",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box>
            <Button
              variant="outlined"
              onClick={() =>
                navigate("/user/account-type/" + params.row.id + "/affinity")
              }
            >
              Assign User Affinity
            </Button>
          </Box>
        );
      },
    },
  ];

  const reload = useCallback(() => {
    setIsLoaded(false);
    AccountTypeAPI.byOrganizationId(props.organizationId).then(
      (accountTypes: Array<AccountType>) => {
        setAccountTypes(accountTypes);
        setIsLoaded(true);
      },
      handleRejectionWithError,
    );
  }, [handleRejectionWithError, props.organizationId]);

  useEffect(() => {
    reload();
  }, [reload]);

  const loaded = (
    <StyledDataGrid
      checkboxSelection
      disableRowSelectionOnClick
      rows={accountTypes}
      pageSizeOptions={[10]}
      columns={accountTypeColumns}
      columnVisibilityModel={{
        id: false,
      }}
      onRowSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel);
      }}
      rowSelectionModel={selectionModel}
    />
  );

  const menu = <></>;

  return (
    <CommonDatagridWrapper
      menu={menu}
      isLoaded={isLoaded}
      loaded={loaded}
      title="Account Types"
    />
  );
};

export default AccountTypeList;
