/**
 * This global context is used to store / retrieve current role assignments for the
 * logged in user under the current global organization context.
 */

import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Role, User } from "../model";
import { NextTaskInstance, ProcessExecution } from "../model/ProcessExecution";
import { useGlobalUserContext } from "./useGlobalUserContext";
import PATHS from "../components/navigation/_paths";
import useFeature from "./useFeature";
import { useAtom } from "jotai";
import { rightPanelAtom } from "../components/layout/dashboard/RightPanel";
import ChatDrawer from "../features/messaging/ChatDrawer";
import { ProcessExecutionCell } from "../features/process/components/execution";

export type GlobalRoleContentType = {
  roles: Role[];
  accountRoles?: Role[];
  setRoles: Dispatch<SetStateAction<Role[]>>;
  setAccountRoles?: Dispatch<SetStateAction<Role[]>>;
};

export const GlobalRoleContext = createContext<GlobalRoleContentType>({
  roles: [],
  accountRoles: [],
  setRoles: () => {
    return;
  },
  setAccountRoles: () => {
    return;
  },
});

export const useGlobalRoleContext = () => useContext(GlobalRoleContext);

export const userCanComplete = (
  task: NextTaskInstance,
  user: User,
  roles: Array<Role>,
) => {
  if (!task) {
    return true;
  }

  const nextTaskRoleId: string | undefined = task.role?.id;
  const assignedTo: User = task.assignee;
  const assigned = assignedTo
    ? assignedTo.id == user.id
    : !nextTaskRoleId || roles.some((r) => r.id == nextTaskRoleId);

  return assigned;
};

export const RenderTaskTitle = (
  params: GridRenderCellParams,
  navigateToExecution?: (pe: ProcessExecution) => void,
) => {
  const v2 = useFeature("v2", true);
  const { user } = useGlobalUserContext();
  const [, setGlobalChatOpen] = useAtom(rightPanelAtom);

  const navigate = useNavigate();

  const value = params.row.name;

  if (params.row.process_name) {
    return <ProcessExecutionCell {...params} />;
  }
  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (v2) {
          setGlobalChatOpen((prev) => ({
            ...prev,
            variant: "drawer",
            open: true,
            content: () => {
              return (
                <ChatDrawer
                  threadId={params.row.chat_id}
                  globalChatSubject={params.row}
                />
              );
            },
          }));
          return;
        }
        if (navigateToExecution) {
          navigateToExecution(params.row);
          return;
        }
        if (user?.id) {
          navigate(
            PATHS.EXECUTION.linkTo(params.row.process_id, params.row.id),
          );
        } else {
          navigate("/share/process-executions/" + params.row.id);
        }
      }}
      sx={{
        cursor: "pointer",
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...(params.row.unread && {
          fontWeight: "600",
        }),
        //this hover state allows the user to scroll to see the rest of the text of it's larger than the container
        "&:hover": {
          overflow: "auto",
          textOverflow: "clip",
        },
      }}
    >
      {value}
    </Box>
  );
};

export const RenderNextTask = (
  params: GridRenderCellParams,
  navigateToExecution?: (pe: ProcessExecution) => void,
) => {
  const { user } = useGlobalUserContext();
  const { roles } = useGlobalRoleContext();

  const navigate = useNavigate();

  //get task title
  const value = params.row.next_task_instance?.name
    ? params.row.next_task_instance?.name
    : "Review";

  //check if user can complete task
  const canComplete = userCanComplete(
    params.row.next_task_instance,
    user,
    roles,
  );

  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        if (navigateToExecution) {
          navigateToExecution(params.row);
          return;
        }
        if (user?.id) {
          navigate(
            PATHS.EXECUTION.linkTo(params.row.process_id, params.row.id),
          );
        } else {
          navigate("/share/process-executions/" + params.row.id);
        }
      }}
      sx={{
        cursor: "pointer",
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...(canComplete && {
          fontWeight: "600",
          borderBottom: (theme) => `1px solid ${theme.palette.text.primary}`,
        }),
        //this hover state allows the user to scroll to see the rest of the text of it's larger than the container
        "&:hover": {
          overflow: "auto",
          textOverflow: "clip",
        },
      }}
    >
      {value}
    </Box>
  );
};
