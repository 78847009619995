export enum ProfileViewTypes {
  OVERVIEW = "overview",
  PROGRAM_EDITOR = "program editor",
  PROGRAM_VIEWER = "program viewer",
  PROGRAM_BLANK = "program blank",
}

export enum AccountSubpage {
  Flights = "flights",
  Profile = "profile",
  Fields = "fields",
  Programs = "programs",
}

export enum AccountProfileViewer {
  Admin = "admin",
  User = "user",
}
