import React, { useCallback, useEffect, useState } from "react";
import { ProcessCategory } from "../../../model/ProcessCategory";
import { AccountProfileViewer } from "../types";
import { AccountProgram } from "../../../model/AccountProgram";
import { Process, ProcessStatus } from "../../../model/Process";
import { Organization } from "../../../model";
import { AccountDto } from "../../../model/Account";
import { useParams } from "react-router";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { AccountAPI, ProcessAPI } from "../../../api";
import { ACCOUNT_TYPE } from "../../../model/EntityRef";
import accountPrograms from "../../../api/accountPrograms";
import processCategories from "../../../api/processCategories";

type AccountPageContextType = {
  account: AccountDto;
  organization: Organization;
  accountTypeProcesses: Process[];
  programs: AccountProgram[];
  userType: AccountProfileViewer;
  categories?: ProcessCategory[];
};

export const AccountPageContext = React.createContext<
  AccountPageContextType | Record<string, never>
>({});

const useOrganizationAccountPage = (viewer: AccountProfileViewer) => {
  const [accountPageContext, setAccountPageContext] =
    useState<AccountPageContextType>();

  const subjectId = useParams().subjectId;

  const { organization, setOrganization, accountListIsMemberOf, isMember } =
    useGlobalOrganizationContext();
  const isMemberOfOrg = isMember(organization?.id);

  const load = useCallback(async () => {
    // loads all aspects of an account once for smooth page navigation, passes context to children
    const account =
      subjectId && ((await AccountAPI.get(subjectId)) as AccountDto);

    if (account && accountListIsMemberOf) {
      if (!organization) {
        const org =
          account.organization ??
          accountListIsMemberOf.find((a) => account.id === a.id)?.organization;
        setOrganization(org);
      } else {
        const newFields = {
          account: account,
          organization: organization,
          accountTypeProcesses: await ProcessAPI.byEntity(
            organization.id,
            {
              entity_type: ACCOUNT_TYPE,
              entity_id: account.account_type_id ?? "",
              counterparty_can_execute: isMemberOfOrg ? undefined : true,
            },
            account.is_test ? ProcessStatus.Draft : ProcessStatus.Published,
          ),
          programs: await accountPrograms.loadByAccountId(account.id),
          userType: viewer,
          categories:
            viewer == AccountProfileViewer.Admin && isMemberOfOrg
              ? await processCategories.byOrganization(organization.id)
              : undefined,
        };
        setAccountPageContext((prevState) => {
          return {
            ...prevState,
            ...newFields,
          };
        });
      }
    }
  }, [
    accountListIsMemberOf,
    isMemberOfOrg,
    organization,
    setOrganization,
    subjectId,
    viewer,
  ]);

  useEffect(() => {
    load();
  }, [load]);
  return accountPageContext;
};

export default useOrganizationAccountPage;
