import {
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import {
  TextFormElement,
  TextFormElementExecution,
  TextFormDataType,
} from "../../../types";
import { FormElementChangeType } from "../FormBuilder";

export const TextSideSectionDisplay = ({
  element,
  onChange,
}: {
  element: TextFormElement;
  executionData?: TextFormElementExecution;
  onChange: (props: FormElementChangeType) => void;
}) => {
  const type = element.textArea
    ? TextFormDataType.TextArea
    : element.richText
      ? TextFormDataType.RichText
      : element.number
        ? TextFormDataType.NumberTextField
        : element.email
          ? TextFormDataType.EmailTextField
          : TextFormDataType.TextField;
  return (
    <FormControl>
      <FormLabel id="form-element-text-type-label">Type</FormLabel>
      <RadioGroup
        aria-labelledby="form-element-text-type-label"
        name="form-element-text-type"
        onChange={(evt, value) => {
          const v = parseInt(value);
          const textArea = v === TextFormDataType.TextArea;
          const richText = v === TextFormDataType.RichText;
          const number = v === TextFormDataType.NumberTextField;
          const email = v === TextFormDataType.EmailTextField;
          onChange({
            changeType: "update",
            newElement: {
              ...element,
              textArea,
              richText,
              number,
              email,
            },
          });
        }}
        value={type}
      >
        <FormControlLabel
          value={TextFormDataType.TextField}
          label="Text Field"
          control={<Radio />}
        />
        <FormControlLabel
          value={TextFormDataType.TextArea}
          label="Text Area"
          control={<Radio />}
        />
        <FormControlLabel
          value={TextFormDataType.RichText}
          label="Rich Text"
          control={<Radio />}
        />
        <FormControlLabel
          value={TextFormDataType.NumberTextField}
          label="Number"
          control={<Radio />}
        />
        <FormControlLabel
          value={TextFormDataType.EmailTextField}
          label="Email"
          control={<Radio />}
        />
      </RadioGroup>
    </FormControl>
  );
};
