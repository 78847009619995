import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { PreferenceContainer } from "../../model/Preference";
import PreferenceAPI from "../../api/preferences";
import { currentUserId } from "../../lib/auth";
import { useAlert } from "../../lib/alert";
import {
  PageContainer,
  PageContent,
  PageHeader,
} from "../../components/layout/page";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";

const UserPreferencePage = () => {
  const { handleRejectionWithError } = useAlert();
  const [preferences, setPreferences] = useState<PreferenceContainer | void>();

  const { setOrganization } = useGlobalOrganizationContext();

  const savePreference = (preference: PreferenceContainer) => {
    setPreferences(preference);
    PreferenceAPI.set(currentUserId(), preference).catch(
      handleRejectionWithError,
    );
  };

  useEffect(() => {
    setOrganization(undefined);
  }, [setOrganization]);

  useEffect(() => {
    PreferenceAPI.get(currentUserId()).then((res) => {
      setPreferences(res);
    });
  }, []);

  return (
    <PageContainer variant="full">
      <PageHeader title="Notification Preferences"></PageHeader>
      <PageContent>
        <Grid container p={2}>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(value) => {
                      savePreference({
                        ...preferences,
                        notifications: {
                          ...preferences?.notifications,
                          notifyMeByEmailWhenANewTaskForARoleIHaveIsNotYetAssigned:
                            value.target.checked,
                        },
                      });
                    }}
                    checked={
                      preferences?.notifications
                        ?.notifyMeByEmailWhenANewTaskForARoleIHaveIsNotYetAssigned ==
                      true
                    }
                  />
                }
                label="Notify me by email when a new task for a role I have is not yet assigned"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </PageContent>
    </PageContainer>
  );
};

export default UserPreferencePage;
