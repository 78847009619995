import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, Box } from "@mui/material";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import PATHS from "../../navigation/_paths";
import useFeature from "../../../hooks/useFeature";
import ManageOrganizationMenu from "../../../features/organization/components/ManageOrganizationMenu";
import { OrganizationTab } from "./OrganizationTab";
import AccountButton from "../top-bar/components/AccountButton";

export const OrganizationSelector = () => {
  const canCreateOrganization = useFeature("organization.create");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    organization,
    organizations,
    setOrganization,
    accountListIsMemberOf,
  } = useGlobalOrganizationContext();
  return (
    <Box
      id="organization-selector"
      sx={{
        displayPrint: "none",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 2,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={organization?.key ?? false}
        TabIndicatorProps={{ hidden: true }}
        textColor={"inherit"}
        sx={{
          flex: 1,
          "& .MuiTab-root": {
            "&:first-of-type": {
              mt: 1,
            },
          },
        }}
      >
        {organizations.map((organization) => (
          <OrganizationTab
            key={organization.id}
            value={organization.key}
            organization={organization}
            onClick={() => {
              setOrganization(organization);
              if (
                !pathname.startsWith("/user/organization/") ||
                pathname.search(
                  /[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[4][0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}/,
                ) >= 0
              ) {
                navigate(PATHS.ORGANIZATION_EXECUTIONS.link);
              }
            }}
          />
        ))}
        {accountListIsMemberOf.map((account) => {
          if (!account.organization) return;
          return (
            <OrganizationTab
              key={account.organization.id}
              value={account.organization.key}
              organization={account.organization}
              onClick={() => {
                setOrganization(account.organization);
                navigate(PATHS.ACCOUNT_PORTAL.linkTo(account.id));
              }}
            />
          );
        })}
      </Tabs>
      {canCreateOrganization && <ManageOrganizationMenu />}
      <AccountButton />
    </Box>
  );
};
