/**
 * This is for listing the accounts within an organization.
 */
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";

import { GridRowSelectionModel } from "@mui/x-data-grid";
import Invitation from "../../../../model/Invitation";
import { CommonDatagridWrapper } from "../../../../components/elements";
import { useAlert } from "../../../../lib/alert";
import { AccountProvider, InvitationProvider } from "../../../../providers";
import { CombinedAccountAndInvitationModel } from "../../../../types";
import { Account } from "../../../../model/Account";
import { organizationAccountsListColumns } from "./columns";
import { AccountType } from "../../../../model/AccountType";
import CreateAccountDialog from "../dialogs/CreateAccountDialog";
import { useNavigate } from "react-router-dom";
import { AddCircle, CloudDownload, DeleteOutlined } from "@mui/icons-material";
import EditAccountTypeDialog from "../EditAccountTypeDialog";
import pluralize from "pluralize";
import { DeleteDialog } from "../../../../components/dialogs";
import { AccountAPI, AccountTypeAPI } from "../../../../api";
import PATHS from "../../../../components/navigation/_paths";
import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";
import DatagridToolbar from "../../../../components/elements/datagrid/DatagridToolbar";
import ToolbarButton from "../../../../components/elements/datagrid/ToolbarButton";
import processMessages from "../../../process/messages";
import StyledDataGrid, {
  TEST_ROW_CLASS_NAME,
} from "../../../../components/elements/datagrid/StyledDataGrid";

import TestModeStatus from "../../../../components/layout/top-bar/components/TestModeStatus";
import TestModeToggle from "../../../../components/layout/top-bar/components/TestModeToggle";
import DatagridToolbarMenuButton from "../../../../components/elements/datagrid/DatagridToolbarMenuButton";
import downloadApiFile from "../../../../util/downloadApiFile";
import { useAtom } from "jotai";
import { testModeAtom } from "../../../process/state";

type CommonOrganizationAccountsProps = {
  organizationId: string;
  invitationDialogOpen?: boolean;
  isDirty?: boolean;
  onOpenInvitationDialog?: () => void;
  accounts: AccountProvider;
  accountType?: AccountType;
  fetchAccounts: (download?: boolean) => Promise<Account[] | Blob>;
  invitations: InvitationProvider;
  onDataLoaded?: (
    accounts: Array<Account>,
    invitations: Array<Invitation>,
  ) => void;
  allowInvitation?: boolean;
};

const CommonOrganizationAccounts: FC<CommonOrganizationAccountsProps> = ({
  accountType: accountTypeProp,
  onDataLoaded,
  fetchAccounts,
  ...props
}) => {
  const navigate = useNavigate();
  const [testMode] = useAtom(testModeAtom);

  // TODO: Need to pull column definition out of the fields that the
  // user object exposes by API

  const { handleRejectionWithError } = useAlert();
  const { organization, setOrganization } = useGlobalOrganizationContext();

  const [createAccountDialogOpen, setCreateAccountDialogOpen] = useState(false);
  const [
    confirmDeleteAccountTypeDialogOpen,
    setConfirmDeleteAccountTypeDialogOpen,
  ] = useState(false);
  const [editAccountTypeDialogOpen, setEditAccountTypeDialogOpen] =
    useState(false);

  const [accountsAndInvitationsCombined, setAccountsAndInvitationsCombined] =
    useState<Array<CombinedAccountAndInvitationModel>>([]);

  const [userSelectionModel, setUserSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [numRowsSelected, setNumRowsSelected] = useState<number>(0);

  const [isLoaded, setIsLoaded] = useState(false);
  const [accountType, setAccountType] = useState(accountTypeProp);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const finishLoading = useCallback(
    (accounts: Array<Account>) => {
      let accountsAndInvitations: Array<CombinedAccountAndInvitationModel> = [];

      accountsAndInvitations = accountsAndInvitations.concat(accounts);

      setAccountsAndInvitationsCombined(accountsAndInvitations);
      setIsLoaded(true);
      if (onDataLoaded) {
        onDataLoaded(accounts, []);
      }
    },
    [onDataLoaded],
  );

  const pluralMsg = useMemo(() => {
    return accountType ? pluralize.plural(accountType.name) : "Accounts";
  }, [accountType]);
  const msg = useMemo(() => {
    return accountType ? accountType.name : "Account";
  }, [accountType]);

  const reload = useMemo(
    () =>
      debounce(
        () => {
          setIsLoaded(false);
          fetchAccounts().then((result) => {
            reload.cancel();
            const accounts = result as unknown as Account[];
            finishLoading(accounts);
          }, handleRejectionWithError("Failed to load invitations and users for organization"));
        },
        5000,
        { leading: true },
      ),
    [finishLoading, handleRejectionWithError, fetchAccounts],
  );

  const dateString = new Date().toISOString().slice(0, 10);

  const exportAccounts = () => {
    fetchAccounts(true)
      .then((blob) =>
        downloadApiFile(
          blob as Blob,
          `${organization?.key ?? organization?.name}-${
            accountType?.key ?? "accounts"
          }-${dateString}.xlsx`,
        ),
      )
      .catch(handleRejectionWithError);
  };

  useEffect(() => {
    reload();
  }, [reload]);

  useEffect(() => {
    if (props.isDirty && props.organizationId) {
      reload();
    }
  }, [reload, props.organizationId, props.isDirty]);

  const createAccountTitle = accountType
    ? `Add ${accountType.name}`
    : "Add Account";

  const deleteBtn = (
    <ToolbarButton
      dataCy="delete-account-btn"
      startIcon={<DeleteOutlined />}
      onClick={() => setDeleteDialogOpen(true)}
      textContent="Delete"
      key="delete"
    />
  );

  const createAccountBtn = (
    <ToolbarButton
      onClick={() => setCreateAccountDialogOpen(true)}
      startIcon={<AddCircle />}
      textContent={createAccountTitle}
    />
  );

  const flightPlansBtn = accountType && (
    <ToolbarButton
      variant="text"
      onClick={() => navigate("processes")}
      color="inherit"
      startIcon={PATHS.PROCESSES.icon}
      textContent={processMessages.processes}
    />
  );

  const accountTypeMenuItems = accountType
    ? [
        {
          label: "Edit Account Type",
          onClick: () => {
            setEditAccountTypeDialogOpen(true);
          },
          separator: true,
        },
        {
          label: "Delete Account Type",
          icon: DeleteOutlined,
          id: "delete-account-type-btn",
          onClick: () => {
            setConfirmDeleteAccountTypeDialogOpen(true);
          },
        },
      ]
    : [];

  const accountTypeMenuButton = accountType && (
    <DatagridToolbarMenuButton
      items={[
        {
          label: "Export",
          icon: CloudDownload,
          onClick: () => {
            exportAccounts();
          },
        },
        {
          component: ({ onClick }) => (
            <TestModeToggle
              objectTitle={pluralMsg}
              variant="menuItem"
              onUpdate={() => {
                reload();
                onClick();
              }}
            />
          ),
        },
        ...accountTypeMenuItems,
      ]}
    />
  );

  const testModeStatus = <TestModeStatus objectTitle={pluralMsg} />;

  const countMsg = useMemo(() => {
    const testAccounts = accountsAndInvitationsCombined.filter((a) => {
      if ((a as Account).is_test) return a;
    });

    const nonTestCount =
      accountsAndInvitationsCombined.length - testAccounts.length;

    if (testMode) {
      return `${nonTestCount} ${nonTestCount == 1 ? msg : pluralMsg} and ${
        testAccounts.length
      } ${testAccounts.length == 1 ? " Test" : " Tests"}`;
    }
    return `${accountsAndInvitationsCombined.length} ${
      accountsAndInvitationsCombined.length == 1 ? msg : pluralMsg
    }`;
  }, [accountsAndInvitationsCombined, msg, pluralMsg, testMode]);

  const loaded = (
    <StyledDataGrid
      data-cy="account-data-grid"
      slots={{ toolbar: DatagridToolbar }}
      slotProps={{
        toolbar: {
          countMsg: countMsg,
          testModeToggle: testModeStatus,
          numSelected: numRowsSelected,
          selectedBtns: deleteBtn,
          defaultBtns: (
            <>
              {createAccountBtn}
              {flightPlansBtn}
              {accountTypeMenuButton}
            </>
          ),
        },
      }}
      getRowClassName={(params) => {
        return params.row.is_test ? TEST_ROW_CLASS_NAME : "";
      }}
      checkboxSelection
      disableColumnSelector
      disableRowSelectionOnClick
      hideFooterSelectedRowCount
      rows={accountsAndInvitationsCombined}
      pageSizeOptions={[10]}
      getRowHeight={() => "auto"}
      columns={
        accountType
          ? organizationAccountsListColumns.filter(
              (col) => col.field !== "account_type_name",
            )
          : organizationAccountsListColumns
      }
      columnVisibilityModel={{
        id: false,
      }}
      onRowSelectionModelChange={(newSelectionModel) => {
        setUserSelectionModel(newSelectionModel);
        setNumRowsSelected(newSelectionModel.length);
      }}
      rowSelectionModel={userSelectionModel}
    />
  );

  return (
    <>
      <CommonDatagridWrapper
        PageHeaderProps={{
          actions: null,
          links: accountType
            ? [{ title: "Accounts", link: PATHS.ACCOUNTS.link }]
            : [],
        }}
        isLoaded={isLoaded}
        loaded={loaded}
        title={pluralMsg}
      />
      <CreateAccountDialog
        open={createAccountDialogOpen}
        organizationId={props.organizationId}
        accountType={accountType}
        onClose={() => {
          setCreateAccountDialogOpen(false);
          reload();
        }}
      />
      <EditAccountTypeDialog
        accountType={accountType}
        open={editAccountTypeDialogOpen}
        onClose={() => setEditAccountTypeDialogOpen(false)}
        onSave={(accountType) => {
          setAccountType(accountType);
        }}
        organizationId={props.organizationId}
      />

      {deleteDialogOpen && (
        <DeleteDialog
          title={
            userSelectionModel.length > 1
              ? `Delete ${pluralMsg}`
              : `Delete ${msg}`
          }
          question={`Are you sure you want to delete ${
            userSelectionModel.length === 1
              ? `this ${msg}`
              : `these ${userSelectionModel.length} ${pluralMsg}`
          }?`}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onConfirm={() => {
            return Promise.all(
              userSelectionModel.map((accountId) =>
                AccountAPI.delete(accountId as string),
              ),
            ).then(
              () => {
                reload();
              },
              handleRejectionWithError(
                `One or more ${pluralMsg} could not be deleted`,
              ),
            );
          }}
        />
      )}

      {confirmDeleteAccountTypeDialogOpen && (
        <DeleteDialog
          title={`Delete Account Type`}
          question={`Are you sure you want to delete the "${accountType?.name}" account type?`}
          open={confirmDeleteAccountTypeDialogOpen}
          onClose={() => setConfirmDeleteAccountTypeDialogOpen(false)}
          onConfirm={() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return AccountTypeAPI.delete(accountType!.id!).then(() => {
              // This causes the organization to reload which also refreshes the account types
              // available to the organization in the sidebar
              organization && setOrganization({ ...organization });

              navigate("/user/organization/accounts");
            }, handleRejectionWithError(`The account type could not be deleted`));
          }}
        />
      )}
    </>
  );
};

export default CommonOrganizationAccounts;
