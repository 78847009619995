import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useDisplayOptions } from "../../../hooks/useDisplayOptions";
import VerticalSteppedProcessExecution from "./VerticalSteppedProcessExecution";
import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAlert } from "../../../lib/alert";
import { PageHeader } from "../../../components/layout/page";
import { ProcessExecutionScreen } from "./execution";
import ContentEditable from "../../../components/ContentEditable";
import NotFound from "../../app/NotFound";
import useProcessExecution, {
  ProcessExecutionViewMode,
  ProcessExecutionViewState,
  processExecutionViewModeAtom,
} from "../hooks/useProcessExecution";
import ProcessExecutions from "../../../api/process_executions";
import { ProcessExecutionProvider } from "../hooks";
import { ViewDay, ViewList } from "@mui/icons-material";
import PATHS from "../../../components/navigation/_paths";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { TaskExecution } from "../../task";
import { useAtom } from "jotai";
import { ProcessStructure } from "../../../model/Process";
import { rightPanelAtom } from "../../../components/layout/dashboard/RightPanel";
import { ProcessExecutionSidePanel } from "./ProcessExecutionSidePanel";

const ProcessExecutionView = ({
  processExecutionId,
}: {
  processExecutionId: string;
}) => {
  const { error } = useAlert();
  const { accountListIsMemberOf, isMember } = useGlobalOrganizationContext();
  const processExecutionValues = useProcessExecution(processExecutionId);
  const {
    processExecution,
    process,
    taskExecutions,
    steps,
    stepExecutions,
    assignedRoleUsers,
    viewState,
  } = processExecutionValues;
  const [viewMode, setViewMode] = useAtom(processExecutionViewModeAtom);
  const [, setRightPanel] = useAtom(rightPanelAtom);
  const [attachmentReloadTrigger, setAttachmentReloadTrigger] = useState(0);

  const allTasksView = useMemo(() => {
    return (
      steps?.length > 0 || !(viewMode === ProcessExecutionViewMode.Focused)
    );
  }, [steps?.length, viewMode]);

  const account = useMemo(() => {
    if (isMember(processExecution?.org_id)) {
      return undefined;
    }
    return accountListIsMemberOf.find(
      (account) => account.org_id === processExecution?.org_id,
    );
  }, [accountListIsMemberOf, isMember, processExecution?.org_id]);

  useDisplayOptions({
    showDrawer: false,
    showTopBorder: true,
    topBorderColor: processExecution?.display?.fillColor,
  });

  useEffect(() => {
    setRightPanel((prev) => ({
      ...prev,
      open: true,
      variant: "collapse",
      content: () => {
        return (
          <ProcessExecutionSidePanel
            organizationId={process?.org_id}
            processExecution={processExecution}
            attachmentReloadTrigger={attachmentReloadTrigger}
            assignedRoleUsers={assignedRoleUsers}
          />
        );
      },
    }));
    return () => {
      setRightPanel((prev) => ({
        ...prev,
        open: false,
      }));
    };
  }, [
    assignedRoleUsers,
    attachmentReloadTrigger,
    process?.org_id,
    processExecution,
    setRightPanel,
  ]);

  if (viewState === ProcessExecutionViewState.NotFound) {
    return <NotFound />;
  }

  return (
    <ProcessExecutionProvider value={processExecutionValues}>
      <Grid
        container
        wrap="nowrap"
        direction={"column"}
        height={"100%"}
        sx={{ backgroundColor: "background.paper" }}
      >
        <Grid item container>
          <PageHeader
            title={processExecution?.name}
            variant="primary"
            color={processExecution?.display?.fillColor}
            links={
              isMember(processExecution?.org_id)
                ? [PATHS.ORGANIZATION_EXECUTIONS]
                : [
                    {
                      ...PATHS.ACCOUNT_PORTAL,
                      link: PATHS.ACCOUNT_PORTAL.linkTo(account?.id ?? ""),
                    },
                  ]
            }
          >
            <Typography
              variant="h1"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {processExecution?.name && (
                <ContentEditable
                  value={processExecution?.name}
                  onChange={async (newValue: string) => {
                    const updatedExecution = { ...processExecution };
                    updatedExecution.name = newValue;
                    try {
                      await ProcessExecutions.update(
                        updatedExecution.id,
                        updatedExecution,
                      );
                      return true;
                    } catch (e) {
                      console.error(e);
                      error("Failed to update process execution name");
                      return false;
                    }
                  }}
                />
              )}
            </Typography>
          </PageHeader>
        </Grid>
        <Grid
          item
          container
          overflow={"hidden"}
          height={"100%"}
          wrap="nowrap"
          sx={{ background: "#F4F7F9" }}
        >
          <Grid item container flex={1} wrap="nowrap" overflow={"auto"}>
            {processExecution && process && (
              <>
                {allTasksView && (
                  <Grid
                    item
                    sx={{ width: "60%", minWidth: "480px", margin: "0 auto" }}
                  >
                    <VerticalSteppedProcessExecution
                      steps={steps}
                      process={process}
                      processExecution={processExecution}
                      stepExecutions={stepExecutions}
                      taskExecutions={taskExecutions.filter(
                        (te): te is TaskExecution => !!te,
                      )}
                      assignedRoles={assignedRoleUsers}
                      onShare={() => {
                        setAttachmentReloadTrigger((trigger) => trigger + 1);
                      }}
                    />
                  </Grid>
                )}
                {!allTasksView && (
                  <Grid item flex={1}>
                    <ProcessExecutionScreen />
                  </Grid>
                )}
              </>
            )}
            {process?.structure == ProcessStructure.Flat && (
              <Grid item>
                <ToggleButtonGroup
                  sx={{ m: 2 }}
                  size="small"
                  value={viewMode}
                  color="primary"
                  exclusive
                  onChange={(e, view) => setViewMode(view)}
                >
                  <ToggleButton value={ProcessExecutionViewMode.List}>
                    <Tooltip title="List">
                      <ViewList />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value={ProcessExecutionViewMode.Focused}>
                    <Tooltip title="Focused">
                      <ViewDay />
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ProcessExecutionProvider>
  );
};

const ProcessExecutionViewPage = () => {
  const { processExecutionId } = useParams<{ processExecutionId: string }>();
  if (!processExecutionId) {
    return <NotFound />;
  }
  return <ProcessExecutionView processExecutionId={processExecutionId} />;
};

export default ProcessExecutionViewPage;
