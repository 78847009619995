import { TaskBase, TaskBaseData } from "../Task";
import { FormExecutionType, TaskFormExecutionData } from "./TaskFormExecution";
import { TaskType } from "../TaskType";
import { TaskExecutionState } from "../TaskExecution";

export type TaskForm = TaskBase<
  TaskType.Form,
  TaskFormData,
  TaskFormExecutionData
>;

export type TaskFormData = TaskBaseData & {
  form?: { elements: Array<FormDefinitionType> };
  autofill?: boolean;
  processWithAi?: boolean;
};

export enum FormElementType {
  Selection = "SELECTION",
  MultiSelection = "MULTI_SELECTION",
  Radio = "RADIO",
  Text = "TEXT",
  Date = "DATE",
  Heading = "HEADING",
  Upload = "UPLOAD",
  NestedForm = "NESTED_FORM",
  Checkbox = "CHECKBOX",
  FieldDefinition = "FIELD_DEFINITION",
  Autocomplete = "AUTOCOMPLETE",
}

export enum TextFormDataType {
  // TODO: When adding more FormTextElement types, do so here
  Date,
  TextArea,
  RichText,
  TextField,
  NumberTextField,
  EmailTextField,
}

export enum HeadingType {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
}
export type DataModelReference = {
  objectType: string;
  role?: string;
  path: string;
};

export type FormElement = {
  id: string;
  name: string;
  type?: FormElementType;
  required?: boolean;
  link?: string;
  label: string;
  description?: string;
  taskExecutionState?: TaskExecutionState;
  modelRefs?: DataModelReference[];
};

export type WithNestedElements = {
  elements?: Array<FormDefinitionType>;
};

export type SelectionOption = {
  id: string;
  name: string;
};

export type SelectionFormElement = FormElement & {
  type: FormElementType.Selection;
  options: Array<SelectionOption>;
  selection?: SelectionOption;
};

export type MultiSelectionFormElement = FormElement & {
  type: FormElementType.MultiSelection;
  options: Array<SelectionOption>;
  selection?: Array<SelectionOption>;
};

export type RadioFormElement = FormElement & {
  type?: FormElementType.Radio;
  options: Array<SelectionOption & WithNestedElements>;
  selection?: SelectionOption;
};

export type TextFormElement = FormElement & {
  type?: FormElementType.Text;
  textArea?: boolean;
  richText?: boolean;
  number?: boolean;
  email?: boolean;
  dataType?: TextFormDataType;
  description?: string;
  value?: string;
};

export type DateFormElement = FormElement & {
  type?: FormElementType.Date;
  description?: string;
  value?: string[];
  multiday?: boolean;
  disablePast?: boolean;
  disableWeekend?: boolean;
};

export type AutocompleteFormElement = FormElement & {
  type?: FormElementType.Autocomplete;
  query: string;
  error?: string;
  value?: string;
};

export type HeadingFormElement = FormElement & {
  type?: FormElementType.Heading;
  headingType?: HeadingType;
  isAnchored?: boolean;
  label: string;
};

export type UploadFormElement = FormElement & {
  type?: FormElementType.Upload;
  value?: Array<{ source: string; contentType?: string }>;
};

export type NestedFormElement = FormElement &
  WithNestedElements & {
    type?: FormElementType.NestedForm;
    value?: Array<Array<FormExecutionType>>;
  };

export type CheckboxFormElement = FormElement &
  WithNestedElements & {
    type?: FormElementType.Checkbox;
    value?: boolean;
  };

export type FieldDefinitionFormElement = FormElement &
  WithNestedElements & {
    type?: FormElementType.FieldDefinition;
    field_definition_id?: string;
    elementValues?: Array<FormExecutionType>;
  };

const forEachNestedElement = (
  element: Partial<FormDefinitionType & WithNestedElements>,
  callback: (element: WithNestedElements & Partial<SelectionOption>) => void,
) => {
  switch (element.type) {
    case FormElementType.NestedForm:
    case FormElementType.Checkbox:
      callback(element);
      return;
    case FormElementType.Radio:
      element.options?.forEach((o) => {
        callback(o);
      });
      return;
  }
  // This is called if you pass in an radio element option
  if (element.elements) {
    callback(element);
  }
};

export const findNestedElement = (
  parent: WithNestedElements,
  elementId: string,
  elementKey: string | undefined,
  callback: (found: WithNestedElements) => void,
) => {
  const callbackHelper = (
    ne: WithNestedElements & Partial<SelectionOption>,
  ) => {
    if (ne.id == elementId) {
      if (elementKey) {
        forEachNestedElement(ne, (o) => {
          if (o.id == elementKey) {
            callback(o);
          }
        });
      } else {
        callback(ne);
      }
    } else {
      findNestedElement(ne, elementId, elementKey, callback);
    }
  };
  (parent.elements ?? []).forEach((e) => {
    callbackHelper(e);
    forEachNestedElement(e, callbackHelper);
  });
};

export type FormDefinitionType =
  | SelectionFormElement
  | MultiSelectionFormElement
  | RadioFormElement
  | TextFormElement
  | DateFormElement
  | HeadingFormElement
  | UploadFormElement
  | NestedFormElement
  | CheckboxFormElement
  | FieldDefinitionFormElement
  | AutocompleteFormElement;
