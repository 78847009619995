import { Link } from "react-router-dom";
import { Tab, TabProps, Typography, styled } from "@mui/material";

export const OrganizationAccountPageTab = styled(
  ({
    label,
    value,
    ...props
  }: Omit<TabProps<typeof Link>, "label" | "value" | "to"> & {
    label: string;
    value: string;
  }) => {
    return (
      <Tab
        {...props}
        label={<Typography variant="subtitle2">{label}</Typography>}
        component={Link}
        value={value}
        to={value}
      />
    );
  },
)(() => ({
  textTransform: "none",
}));
