import { FC, useCallback, useEffect, useState } from "react";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  TaskExecutionState,
  TextFormElement,
  TextFormDataType,
} from "../../../../types";
import ReactQuillEditor from "../../../../../../components/core/ReactQuill/ReactQuillEditor";
import ReactQuillViewerComplete from "../../../../../../components/core/ReactQuill/ReactQuillViewer";
import { useAtom } from "jotai";
import { eventsAtom } from "../../../../hooks/useEvents";
import jp from "jsonpath";
import FormElementTaskDescription from "../../../form-builder/FormElementTaskDescription";
import { AlternateEmail, Numbers } from "@mui/icons-material";
import { useValidation } from "../../../../hooks/useValidation";
import { validateEmail } from "../../../../../../components/core/utils";
import { formatTextFormElementData } from "../../../../../../lib/format";

type TaskTextFormType = {
  formId?: string;
  disabled: boolean;
  onValueChange: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execution: any;
} & TextFormElement;

const TaskTextFormComponent: FC<TaskTextFormType> = ({
  execution,
  onValueChange,
  taskExecutionState,
  ...props
}) => {
  const [events] = useAtom(eventsAtom);
  const [value, setValue] = useState<string>(execution?.value || "");
  const [hasError, setHasError] = useState(false);
  const { handleValidation } = useValidation();

  const type = props.textArea
    ? TextFormDataType.TextArea
    : props.richText
      ? TextFormDataType.RichText
      : props.number
        ? TextFormDataType.NumberTextField
        : props.email
          ? TextFormDataType.EmailTextField
          : TextFormDataType.TextField;

  const submitValue = useCallback(
    (value: string) => {
      setValue(value);
      onValueChange(value);
    },
    [onValueChange],
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      submitValue(value);
      if (type == TextFormDataType.EmailTextField) {
        const fieldIsEmpty = value.length === 0;
        const valid =
          !!validateEmail(value) || (!props.required && fieldIsEmpty);
        setHasError(!valid);
        handleValidation(e.currentTarget.id, valid);
      }
    },
    [handleValidation, props.required, submitValue, type],
  );

  useEffect(() => {
    if (execution?.value) {
      submitValue(
        formatTextFormElementData(
          value ?? execution?.value,
          execution?.dataType,
        ) ?? "",
      );
    }
  }, [submitValue, execution?.value, execution?.dataType, value]);

  useEffect(() => {
    //if (props.link) {
    const listener = (object: Record<string, unknown>) => {
      if (props.link) {
        const value = jp.value(object, props.link ?? "");
        setValue(value);
        submitValue(value);
      }
    };
    events.on(`object`, listener);
    return () => {
      events.off(`object`, listener);
    };
    //}
  }, [events, props.link, props.name, submitValue]);

  const label = (
    <Typography variant="body1" sx={{ display: "inline" }}>
      {props.label}
    </Typography>
  );

  // Readonly view
  if (props.disabled && taskExecutionState === TaskExecutionState.Completed) {
    return (
      <Grid item>
        {type === TextFormDataType.TextArea && (
          <Grid container gap={1} alignItems={"center"}>
            <Typography variant="subtitle1">{props.label}:</Typography>
            <TextField
              multiline
              rows={3}
              name={props.name}
              fullWidth
              variant="outlined"
              type="text"
              disabled
              InputProps={{ readOnly: props.disabled }}
              value={execution?.value}
              inputProps={{ style: { fontSize: "0.875rem" } }}
              sx={{ bgcolor: "common.white" }}
            />
          </Grid>
        )}
        {type === TextFormDataType.RichText && (
          <Grid container gap={1} alignItems={"center"}>
            <Typography variant="subtitle1">{props.label}:</Typography>
            <ReactQuillViewerComplete
              value={execution?.value}
              variant="regular"
            />
          </Grid>
        )}
        {(type === TextFormDataType.TextField ||
          type === TextFormDataType.EmailTextField ||
          type === TextFormDataType.NumberTextField) && (
          <Grid container gap={1} alignItems={"center"}>
            <Typography variant="subtitle1">{props.label}:</Typography>
            <Typography>{execution?.value}</Typography>
          </Grid>
        )}
      </Grid>
    );
  }
  return (
    <Grid item>
      <FormGroup id={props.formId}>
        {type === TextFormDataType.TextArea && (
          <TextField
            multiline
            label={label}
            rows={5}
            name={props.name}
            required={props.required || false}
            onChange={handleOnChange}
            variant="outlined"
            type="text"
            InputProps={{ readOnly: props.disabled }}
            value={value}
            helperText={props.description}
            inputProps={{ style: { fontSize: "0.875rem" } }}
            sx={{ bgcolor: "common.white" }}
          />
        )}
        {type === TextFormDataType.TextField && (
          <TextField
            fullWidth
            label={label}
            name={props.name}
            required={props.required || false}
            onChange={handleOnChange}
            variant="outlined"
            type="text"
            InputProps={{ readOnly: props.disabled }}
            value={value}
            helperText={props.description}
            inputProps={{ style: { fontSize: "0.875rem" } }}
            sx={{ bgcolor: "common.white" }}
          />
        )}
        {type === TextFormDataType.RichText && (
          <FormControl>
            <FormLabel id={`${props.id}-label`} required={props.required}>
              {label}
            </FormLabel>
            <FormElementTaskDescription>
              {props.description}
            </FormElementTaskDescription>
            <ReactQuillEditor
              aria-labelledby={`${props.id}-label`}
              disabled={props.disabled}
              onChange={submitValue}
              value={value}
              id={props.id}
            />
          </FormControl>
        )}
        {type === TextFormDataType.NumberTextField && (
          <TextField
            fullWidth
            label={label}
            name={props.name}
            required={props.required || false}
            onChange={handleOnChange}
            variant="outlined"
            type="number"
            InputProps={{
              readOnly: props.disabled,
              endAdornment: <Numbers />,
            }}
            value={value}
          />
        )}
        {type === TextFormDataType.EmailTextField && (
          <TextField
            fullWidth
            label={label}
            name={props.name}
            error={hasError}
            required={props.required || false}
            onChange={handleOnChange}
            variant="outlined"
            type="email"
            InputProps={{
              readOnly: props.disabled,
              endAdornment: <AlternateEmail />,
            }}
            value={value}
            helperText={hasError ? "Must be valid email" : props.description}
            inputProps={{ style: { fontSize: "0.875rem" } }}
            sx={{ bgcolor: "common.white" }}
          />
        )}
      </FormGroup>
    </Grid>
  );
};

export default TaskTextFormComponent;
