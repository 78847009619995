import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import StatusChipCell from "../cells/StatusChipCell";
import { UserOrganizationRelationshipType } from "../../../../../types";

export const accountOrganizationsListColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    sortable: true,
  },
  {
    field: "account_name",
    headerName: "Account Name",
    sortable: true,
    flex: 1,
    minWidth: 150,
    valueGetter: (value, row): string => {
      if (row.account_name) {
        return row.account_name;
      }

      if (row.user_first_name && row.user_last_name) {
        return `${row.user_first_name} ${row.user_last_name}`;
      }
      return "";
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 100,
    maxWidth: 120,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    valueGetter: () => {
      return "Pending";
    },
    renderCell: (params: GridRenderCellParams) => (
      <StatusChipCell
        isPending={!!params.row.target_user_email}
        label={UserOrganizationRelationshipType.Account}
      />
    ),
  },
];

export default accountOrganizationsListColumns;
