import { PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import { OrganizationSelector, TopBar } from "..";
import { environment } from "../../../util";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { Paper, PaperProps, styled } from "@mui/material";
import { Organization } from "../../../model";

const isEnterprise = !!environment.organization?.key;

const DashboardContent = styled(Paper)<
  PaperProps & { organization?: Organization }
>(({ theme, organization }) => ({
  overflow: "auto",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  margin: isEnterprise ? 0 : "1em",
  marginLeft: 0,
  border: isEnterprise
    ? undefined
    : !organization
      ? `1px solid ${theme.palette.grey[400]}`
      : `1px solid ${theme.palette.primary.main}`,
  // Printing overrides
  "@media print": {
    margin: 0,
    border: "none",
    boxShadow: "none",
  },
}));

export const DashboardLayout = ({ children }: PropsWithChildren<unknown>) => {
  const { organizations, organizationsLoaded, organization } =
    useGlobalOrganizationContext();

  // if no organizations loaded return empty
  if (!organizationsLoaded) {
    return <></>;
  }

  const isAdmin = organizations.find((org) => {
    return org.key === environment.organization?.key;
  });

  return (
    <Box
      id="dashboard-layout"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Top bar is shown only on account portal pages for account user */}
      {isEnterprise && !isAdmin && <TopBar position={"static"} />}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
          "@media print": {
            overflow: "visible",
          },
        }}
      >
        {!isEnterprise && <OrganizationSelector />}
        <DashboardContent organization={organization} elevation={3}>
          {children}
        </DashboardContent>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
