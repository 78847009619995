import { Drawer, Collapse, Paper } from "@mui/material";
import { atom, useAtom } from "jotai";

const DEFAULT_WIDTH = "600px";

type RightPanelVariant = "drawer" | "collapse";

export const RightPanel = () => {
  const [{ variant, open, content }] = useAtom(rightPanelAtom);
  switch (variant) {
    case "drawer":
      return (
        <Drawer
          open={open}
          anchor="right"
          variant={"persistent"}
          PaperProps={{
            elevation: 12,
            sx: {
              overflow: "visible",
              width: DEFAULT_WIDTH,
            },
          }}
        >
          {content?.()}
        </Drawer>
      );
    case "collapse":
      return (
        <Collapse in={open} orientation={"horizontal"}>
          <Paper
            sx={{
              height: "100%",
              position: "relative",
              display: "flex",
              width: DEFAULT_WIDTH,
            }}
          >
            {content?.()}
          </Paper>
        </Collapse>
      );
  }
};

export const rightPanelAtom = atom<{
  open: boolean;
  variant: RightPanelVariant;
  content?: () => JSX.Element;
}>({
  open: false,
  variant: "drawer",
});
