import { Routes, Route, Navigate } from "react-router";
import { AccountProfilePage } from "../features/accounts";
import {
  AccountProfileViewer,
  AccountProfileSubpage,
} from "../features/accounts/AccountProfilePage";
import OrganizationAccountPage from "../features/accounts/OrganizationAccountPage";
import OrganizationAccountFlights from "../features/accounts/components/OrganizationAccountFlights";
import NotFound from "../features/app/NotFound";
import useFeature from "../hooks/useFeature";
import { useAccountProgramTab } from "../features/accounts/hooks/useAccountProgramTab";

type AccountPageRoutesProps = {
  viewer: AccountProfileViewer;
};

export const AccountPageRoutes = ({ viewer }: AccountPageRoutesProps) => {
  const newAccountPage = useFeature("newAccountPage");
  const { showProgramsTab } = useAccountProgramTab(viewer);

  if (newAccountPage) {
    return (
      <Routes>
        <Route
          path=":subjectId"
          element={<OrganizationAccountPage viewer={viewer} />}
        >
          <Route
            path="flights"
            element={<OrganizationAccountFlights viewer={viewer} />}
          />
          <Route path="profile" element={<p>Other content!</p>} />
          {showProgramsTab && (
            <Route path="programs" element={<p>Other content!</p>} />
          )}
          <Route path="" element={<Navigate to={"flights"} replace={true} />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path=":subjectId">
        <Route
          path="overview"
          element={
            <AccountProfilePage
              view={AccountProfileSubpage.Overview}
              viewer={viewer}
            />
          }
        />
        <Route
          path="program"
          element={
            <AccountProfilePage
              view={AccountProfileSubpage.ProgramViewer}
              viewer={viewer}
            />
          }
        />
        {viewer === AccountProfileViewer.Admin && (
          <Route
            path="program/editor"
            element={
              <AccountProfilePage
                view={AccountProfileSubpage.ProgramEditor}
                viewer={viewer}
              />
            }
          />
        )}
        <Route path="" element={<Navigate to={"overview"} replace={true} />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
