import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  ActivityCell,
  CompletedByCell,
  TimestampCell,
  ProcessPhaseChipCell,
} from "./cells";

export const visibilityColumns = {
  id: false,
};

const activityListColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    sortable: true,
  },
  {
    field: "event_type",
    headerName: "Activity",
    sortable: false,
    flex: 2,
    renderCell: (params: GridRenderCellParams) => (
      <ActivityCell auditEvent={params.row} />
    ),
  },
  {
    field: "phase",
    headerName: "Phase",
    sortable: true,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <ProcessPhaseChipCell
          name={params.row.process?.name ?? "Not Found"}
          display={params.row.process?.display}
        />
      );
    },
    valueGetter: (value, row) => row.process?.name,
  },
  {
    field: "completed_by",
    headerName: "Completed by:",
    sortable: true,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <CompletedByCell user={params.row.user} />
    ),
    valueGetter: (value, row) => row.user,
    sortComparator: (u1, u2) => {
      //Always put blank fields at the bottom
      if (!u1 || !u2) return 0;
      return u1.first_name.localeCompare(u2.first_name);
    },
  },
  {
    field: "timestamp",
    headerName: "Date & Time",
    sortable: true,
    width: 250,
    renderCell: (params: GridRenderCellParams) => (
      <TimestampCell time={params.value} />
    ),
  },
];

export default activityListColumns;
