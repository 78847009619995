import { Box, Divider, Typography } from "@mui/material";
import ProcessExecutionUpdated from "./ProcessExecutionStarted";
import ProcessPhaseChip from "./ProcessPhaseChip";
import ProcessProgress from "./ProcessProgress";
import { ProcessExecutionDetailTabs } from "./execution";
import { SideDetailsSection } from "./side-details";
import SideDetailsContainer from "./side-details/SideDetailsContainer";
import { ProcessExecution } from "../../../model/ProcessExecution";
import { AssignedRoleUserDto } from "../../../types";

export const ProcessExecutionSidePanel = ({
  organizationId,
  processExecution,
  attachmentReloadTrigger,
  assignedRoleUsers,
}: {
  organizationId?: string;
  processExecution?: ProcessExecution;
  attachmentReloadTrigger: number;
  assignedRoleUsers: AssignedRoleUserDto[];
}) => {
  return (
    <SideDetailsContainer
      scrollable={true}
      sx={{ display: "flex", flexDirection: "column", width: "600px" }}
    >
      <SideDetailsSection sx={{ display: "flex", gap: 3 }}>
        <Box sx={{ flex: 0 }}>
          {processExecution && <ProcessPhaseChip {...processExecution} />}
        </Box>
        <Box sx={{ flexBasis: "auto" }}>
          {processExecution && (
            <ProcessExecutionUpdated {...processExecution} />
          )}
        </Box>
      </SideDetailsSection>
      <Divider />
      <SideDetailsSection>
        <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
          Progress:
        </Typography>
        <Box sx={{ flexBasis: "auto", minWidth: "200px" }}>
          {processExecution && (
            <ProcessProgress processExecution={processExecution} />
          )}
        </Box>
      </SideDetailsSection>
      <Divider />
      {processExecution && organizationId && (
        <ProcessExecutionDetailTabs
          processExecution={processExecution}
          organizationId={organizationId}
          attachmentReloadTrigger={attachmentReloadTrigger}
          assignedRoleUsers={assignedRoleUsers}
        />
      )}
    </SideDetailsContainer>
  );
};
