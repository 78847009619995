import { useMemo } from "react";
import useFeature from "../../../hooks/useFeature";
import { AccountProfileViewer } from "../AccountProfilePage";
import { AccountProgram } from "../../../model/AccountProgram";

export const useAccountProgramTab = (
  viewer: AccountProfileViewer,
  programs?: AccountProgram[],
) => {
  const programsFF = useFeature("program");
  const showProgramsTab = useMemo(() => {
    if (!programsFF) {
      return false;
    }
    if (!programs) {
      return false;
    }
    switch (viewer) {
      case AccountProfileViewer.User:
        return (
          programs.filter((program) => {
            return program.visible;
          }).length > 0
        );
      case AccountProfileViewer.Admin:
        return true;
    }
    return false;
  }, [programs, programsFF, viewer]);

  return {
    showProgramsTab,
  };
};
