import { Outlet, useLocation } from "react-router-dom";
import { Box, Tabs } from "@mui/material";
import { AccountProfileViewer, AccountSubpage } from "./types";
import useOrganizationAccountPage, {
  AccountPageContext,
} from "./hooks/useOrganizationAccountPage";
import { OrganizationAccountPageTab } from "./OrganizationAccountPageTab";
import { useAccountProgramTab } from "./hooks/useAccountProgramTab";

type AccountProfilePageProps = {
  viewer: AccountProfileViewer;
};

const OrganizationAccountPage = ({ viewer }: AccountProfilePageProps) => {
  const { pathname } = useLocation();
  const accountPageContext = useOrganizationAccountPage(viewer);
  const { showProgramsTab } = useAccountProgramTab(
    viewer,
    accountPageContext?.programs,
  );

  const currentTab = Object.values(AccountSubpage).find((path) => {
    if (path == AccountSubpage.Programs && !showProgramsTab) {
      return false;
    }
    return pathname.endsWith(`/${path}`);
  });

  if (!accountPageContext?.account) {
    return <></>;
  }

  return (
    <>
      <Tabs value={currentTab ?? false}>
        <OrganizationAccountPageTab
          label={`Flights (${accountPageContext.accountTypeProcesses.length})`}
          value={AccountSubpage.Flights}
        />
        <OrganizationAccountPageTab
          label={`Profile`}
          value={AccountSubpage.Profile}
        />
        {showProgramsTab && (
          <OrganizationAccountPageTab
            label={`Programs`}
            value={AccountSubpage.Programs}
          />
        )}
      </Tabs>
      <AccountPageContext.Provider value={accountPageContext}>
        <Box flex={1}>
          <Outlet />
        </Box>
      </AccountPageContext.Provider>
    </>
  );
};

export default OrganizationAccountPage;
