import { Link } from "@mui/material";
import { useAtom } from "jotai";
import { rightPanelAtom } from "../../../../components/layout/dashboard/RightPanel";
import ChatDrawer from "../../../messaging/ChatDrawer";

const ThreadMessageCell = ({
  subject,
  threadId,
}: {
  threadId: string;
  subject: Record<string, unknown>;
}) => {
  const [, setGlobalChatOpen] = useAtom(rightPanelAtom);
  return (
    <Link
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setGlobalChatOpen((prev) => ({
          ...prev,
          variant: "drawer",
          open: true,
          content: () => {
            return (
              <ChatDrawer threadId={threadId} globalChatSubject={subject} />
            );
          },
        }));
      }}
      data-cy={"chat-link"}
      underline="hover"
      sx={{ color: (theme) => theme.palette.text.primary }}
    >
      {"Open Chat"}
    </Link>
  );
};
export default ThreadMessageCell;
