import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

type ValidationContextType = {
  validationFailures: Record<string, boolean>;
  handleValidation: (validationId: string, valid: boolean) => void;
};

const ValidationContext = createContext<
  ValidationContextType | Record<string, never>
>({});

export const ValidationProvider = (props: PropsWithChildren) => {
  const [validationFailures, setValidationFailures] = useState<
    Record<string, boolean>
  >({});

  // Adds or removes validation item from failures based on validity and whether it already exists
  const handleValidation = useCallback(
    (validationId: string, valid: boolean) => {
      setValidationFailures((prev) => {
        if (!valid && !prev[validationId]) {
          return { ...prev, [validationId]: true };
        }
        if (valid && prev[validationId]) {
          const newFailures = { ...prev };
          delete newFailures[validationId];
          return newFailures;
        }
        return prev;
      });
    },
    [],
  );

  return (
    <ValidationContext.Provider
      value={{ validationFailures, handleValidation }}
    >
      {props.children}
    </ValidationContext.Provider>
  );
};

export const useValidation = () => useContext(ValidationContext);
