import { NavigateNext } from "@mui/icons-material";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { ChatAPI } from "../../api";
import { rightPanelAtom } from "../../components/layout/dashboard/RightPanel";
import {
  SideDetailsContainer,
  SideDetailsSection,
} from "../process/components/side-details";
import Chat from "./Chat";
import { ChatDto } from "./model";

const CloseButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: "absolute",
        left: -18,
        top: 19,
        backgroundColor: (theme) => theme.palette.background.drawer,
        borderColor: (theme) => theme.palette.divider,
        borderStyle: "solid",
        borderWidth: "1px",
        color: (theme) => theme.palette.grey[500],
        "&.MuiButtonBase-root": {
          "&.MuiIconButton-root": {
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[400],
            },
          },
        },
      }}
      size="small"
    >
      <NavigateNext />
    </IconButton>
  );
};

//ChatDrawer
const ChatDrawer = ({
  threadId: threadIdProp,
  globalChatSubject,
}: {
  threadId?: string;
  globalChatSubject?: Record<string, unknown>;
}) => {
  const [, setDrawerOpen] = useAtom(rightPanelAtom);
  const [threadId, setThreadId] = useState<string | undefined>(threadIdProp);
  useEffect(() => {
    if (!threadId && globalChatSubject?.id) {
      // This call will create a new chat for the subject on the backend
      ChatAPI.getChatsForSubject(globalChatSubject?.id as string).then(
        (subjectThreads) => {
          if (subjectThreads && subjectThreads.length > 0) {
            setThreadId(subjectThreads[0].id);
          }
        },
        () => {
          // TODO - display warning in chat
        },
      );
    }
  }, [threadId, globalChatSubject?.id]);

  useEffect(() => {
    //Update local state if the threadIdProp changes
    if (threadIdProp !== threadId) {
      setThreadId(threadIdProp);
    }
  }, [threadIdProp]);

  return (
    <>
      <CloseButton
        onClose={() =>
          setDrawerOpen((prev) => ({
            ...prev,
            open: false,
          }))
        }
      />
      <SideDetailsContainer
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "600px",
        }}
      >
        <Divider />
        <SideDetailsSection sx={{ height: 68 }}>
          <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
            {(globalChatSubject?.name as string) ?? "Thread"}
          </Typography>
        </SideDetailsSection>
        <Divider />
        <Box position="relative" flex="1" overflow="hidden">
          {threadId && (
            <Chat
              threadId={threadId}
              chat={globalChatSubject as unknown as ChatDto}
              showExecutions={true}
              sx={{
                "& .ql-toolbar": {
                  backgroundColor: "white",
                },
                "& .ql-container": {
                  backgroundColor: "white",
                },
              }}
            ></Chat>
          )}
        </Box>
      </SideDetailsContainer>
    </>
  );
};

export default ChatDrawer;
