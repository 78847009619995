import { AddCircle, DeleteOutlined, EditOutlined } from "@mui/icons-material";
import {
  DataGridProps,
  GridRenderCellParams,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import debounce from "lodash.debounce";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { CommonDatagridWrapper, RoleIcon } from "../../components/elements";
import { useAlert } from "../../lib/alert";
import Roles from "../../api/roles";
import { Role } from "../../model";
import { Box, Button, TextField } from "@mui/material";
import Dialog from "../../components/Dialog";
import { v4 as uuid } from "uuid";
import { ChromePicker } from "react-color";
import StyledDataGrid from "../../components/elements/datagrid/StyledDataGrid";
import DatagridToolbar from "../../components/elements/datagrid/DatagridToolbar";
import ToolbarButton from "../../components/elements/datagrid/ToolbarButton";
import useIsAdmin from "../../hooks/useIsAdmin";

type OrganizationRolesProps = {
  organizationId: string;
};
export const roleChipCellRenderer = (role: Role) => {
  return (
    <Box
      component={"span"}
      sx={{
        borderRadius: "25px",
        typography: "body1",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: 1,
        pl: 1,
        pr: 1,
        py: 1,
        display: "flex",
        alignItems: "center",
        lineHeight: 1,
      }}
    >
      <Box sx={{ flex: 0, mr: 1 }}>
        <RoleIcon role={role} />
      </Box>
      <Box sx={{ flex: 1 }}> {role.name}</Box>
    </Box>
  );
};

const OrganizationRoles: FC<OrganizationRolesProps> = (
  props: OrganizationRolesProps,
) => {
  const isAdmin = useIsAdmin();
  const rolesColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        return roleChipCellRenderer(params.row);
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 150,
    },
  ];

  const { handleRejectionWithError } = useAlert();

  const [isLoaded, setIsLoaded] = useState(false);
  const [organizationRoles, setOrganizationRoles] = useState<Role[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  );
  const [numRowsSelected, setNumRowsSelected] = useState<number>(0);
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);
  const [roleDialogTitle, setRoleDialogTitle] = useState("");
  const [roleDialogConfirmText, setRoleDialogConfirmText] = useState("");
  const [roleDialogType, setRoleDialogType] = useState<"CREATE" | "UPDATE">(
    "CREATE",
  );
  const [selectedRole, setSelectedRole] = useState<Role>({
    id: uuid(),
    name: "",
    description: "",
    org_id: props.organizationId,
  });
  const [selectedRoleFillColor, setSelectedRoleFillColor] = useState<
    string | undefined
  >();

  const reload = useMemo(
    () =>
      debounce(
        () => {
          setIsLoaded(false);
          Roles.byOrganization(props.organizationId).then((result) => {
            reload.cancel();
            setOrganizationRoles(result);
            setIsLoaded(true);
          }, handleRejectionWithError("Failed to load roles for organization"));
        },
        5000,
        { leading: true },
      ),
    [props.organizationId, handleRejectionWithError],
  );

  useEffect(() => {
    reload();
  }, [reload]);

  const editBtn = (
    <ToolbarButton
      dataCy="edit-role-btn"
      key="editBtn"
      startIcon={<EditOutlined />}
      disabled={numRowsSelected == 1 ? false : true}
      onClick={() => updateRole()}
      textContent="Edit"
    />
  );

  const deleteBtn = (
    <ToolbarButton
      dataCy="delete-role-btn"
      key="deleteBtn"
      startIcon={<DeleteOutlined />}
      onClick={() => deleteSelectedRoles()}
      textContent="Delete"
    />
  );
  const adminProps: Partial<DataGridProps> = {
    checkboxSelection: true,
    onRowSelectionModelChange: (newSelectionModel) => {
      setSelectionModel(newSelectionModel);
      setNumRowsSelected(newSelectionModel.length);
    },
    rowSelectionModel: selectionModel,
  };

  const loaded = (
    <StyledDataGrid
      slots={{ toolbar: DatagridToolbar }}
      slotProps={{
        toolbar: {
          countMsg: `${organizationRoles.length} Roles`,
          numSelected: numRowsSelected,
          selectedBtns: isAdmin ? (
            <>
              {editBtn}
              {deleteBtn}
            </>
          ) : (
            <></>
          ),
        },
      }}
      disableRowSelectionOnClick
      disableColumnSelector
      hideFooterSelectedRowCount
      rows={organizationRoles}
      pageSizeOptions={[10]}
      getRowHeight={() => "auto"}
      columns={rolesColumns}
      {...(isAdmin ? adminProps : {})}
    />
  );

  const createRole = function () {
    setRoleDialogTitle("Create Role");
    setRoleDialogConfirmText("Create");
    setSelectedRole({
      id: uuid(),
      name: "",
      description: "",
      org_id: props.organizationId,
    });
    setRoleDialogType("CREATE");
    setSelectedRoleFillColor(undefined);
    setRoleDialogOpen(true);
  };

  const createRoleConfirm = useCallback(() => {
    setRoleDialogOpen(false);
    return Roles.create(selectedRole).then(() => {
      reload();
    }, handleRejectionWithError("Unable to create role"));
  }, [handleRejectionWithError, reload, selectedRole]);

  const updateRole = function () {
    const selectedRoles: Role[] = selectionModel
      .map((id: GridRowId) => {
        return organizationRoles.find((item) => item.id === id.toString());
      })
      .filter((role) => role) as Role[];

    if (!selectedRoles.length) {
      return;
    }

    setRoleDialogTitle("Update Role");
    setRoleDialogConfirmText("Save");
    setSelectedRole(selectedRoles[0]);
    setRoleDialogType("UPDATE");
    setSelectedRoleFillColor(selectedRoles[0].display?.fillColor);
    setRoleDialogOpen(true);
  };

  const updateRoleConfirm = useCallback(() => {
    setRoleDialogOpen(false);
    return Roles.update(selectedRole).then(() => {
      reload();
    }, handleRejectionWithError("Unable to save role"));
  }, [handleRejectionWithError, reload, selectedRole]);

  const deleteSelectedRoles = () => {
    const promises = selectionModel.map((id: GridRowId) => {
      const found = organizationRoles.find((item) => item.id === id.toString());

      if (!found) {
        console.warn(
          "Unable to find matching model object for invitation or user id: " +
            id.toString(),
        );
        return;
      }

      return Roles.remove(found.id);
    });

    Promise.all(promises).then(() => {
      reload();
    }, handleRejectionWithError("Failed to delete all roles"));
  };

  return (
    <>
      <CommonDatagridWrapper
        isLoaded={isLoaded}
        loaded={loaded}
        title="Roles"
        PageHeaderProps={{
          actions: isAdmin ? (
            <Button
              variant="contained"
              onClick={() => {
                createRole();
              }}
            >
              <AddCircle sx={{ mr: "0.25em" }} />
              Create Role
            </Button>
          ) : undefined,
        }}
      />
      <Dialog
        open={roleDialogOpen}
        title={roleDialogTitle}
        confirmText={roleDialogConfirmText}
        cancelText={"Cancel"}
        handleConfirm={() => {
          if (roleDialogType == "CREATE") {
            return createRoleConfirm();
          } else {
            return updateRoleConfirm();
          }
        }}
        handleCancel={function (): void {
          setRoleDialogOpen(false);
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          id="role-name"
          label="Name"
          type="text"
          fullWidth
          variant="standard"
          value={selectedRole.name}
          onChange={(e) => {
            setSelectedRole({
              ...selectedRole,
              ...{
                name: e.target.value as string,
              },
            });
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="role-description"
          label="Description"
          type="text"
          fullWidth
          variant="standard"
          value={selectedRole.description}
          onChange={(e) => {
            setSelectedRole({
              ...selectedRole,
              ...{
                description: e.target.value as string,
              },
            });
          }}
        />
        <Box sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
          <ChromePicker
            color={selectedRoleFillColor}
            onChange={(color) => {
              setSelectedRoleFillColor(color.hex);
              setSelectedRole({
                ...selectedRole,
                ...{
                  display: {
                    fillColor: color.hex,
                  },
                },
              });
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default OrganizationRoles;
