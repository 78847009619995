import { Link } from "react-router-dom";
import { environment } from "../../../util";
import PATHS from "../../navigation/_paths";
import { AppBar, AppBarProps, styled } from "@mui/material";
import AccountButton from "./components/AccountButton";

const TopBar = styled((props: AppBarProps) => {
  return (
    <AppBar {...props}>
      <Link data-cy={"dashboard-link"} to={PATHS.HOME.link}>
        <img
          data-cy={"app-logo"}
          src={environment.app.logo}
          style={{
            height: "56px",
            paddingLeft: "16px",
            paddingTop: "16px",
            paddingBottom: "4px",
          }}
        />
      </Link>
      <AccountButton />
    </AppBar>
  );
})(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderBottom: "8px solid",
  borderColor: theme.palette.primary.main,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

export default TopBar;
