import { Outlet } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { setAccessToken } from "../../lib/auth";
import { MainContent } from "../../components/layout";
import NotFound from "./NotFound";

const SharedPage = () => {
  // Attempt to read the token from the URL parameters
  const [searchParams] = useSearchParams();
  let token = searchParams.get("share");

  // If the token is not provided, attempt to read it from local storage
  if (!token) {
    const tokenFromStorage = localStorage.getItem("share");
    if (tokenFromStorage) {
      token = tokenFromStorage;
    } else {
      return <NotFound />;
    }
  }

  // Save the token to local storage
  localStorage.setItem("share", token);
  setAccessToken(token);

  return (
    <MainContent>
      <Outlet />
    </MainContent>
  );
};

export default SharedPage;
