import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { EmailCell } from "../cells";
import StatusChipCell from "../cells/StatusChipCell";
import { UserOrganizationRelationshipType } from "../../../../../types";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PATHS from "../../../../../components/navigation/_paths";
import { getAccountName } from "../../../utils";
import OverflowTooltip from "../../../../../components/core/OverflowTooltip/OverflowTooltip";

export const organizationAccountsListColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    sortable: true,
  },
  {
    field: "account_name",
    headerName: "Account Name",
    sortable: true,
    flex: 1,
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <OverflowTooltip title={getAccountName(params.row)}>
          <Link
            underline="hover"
            key={params.row.id}
            component={RouterLink}
            to={PATHS.ACCOUNT_PROFILE.linkTo(params.row.id)}
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {getAccountName(params.row)}
          </Link>
        </OverflowTooltip>
      );
    },
  },
  {
    field: "account_type_name",
    width: 150,
    headerName: "Account Type",
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        underline="hover"
        key={params.row.id}
        component={RouterLink}
        to={PATHS.ACCOUNTS_BY_TYPE.linkTo(params.row.account_type_key)}
        sx={{ color: (theme) => theme.palette.text.primary }}
      >
        {params.row.account_type_name}
      </Link>
    ),
  },
  {
    field: "owner",
    headerName: "Contact",
    sortable: true,
    flex: 1,
    minWidth: 150,
    valueGetter: (value, row): string => {
      if (row.user_first_name && row.user_last_name) {
        return `${row.user_first_name} ${row.user_last_name}`;
      }
      return "";
    },
  },
  {
    field: "email", //need to fix me to display primary email address of account
    headerName: "Email Address",
    sortable: true,
    hideable: false,
    flex: 2,
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => (
      <EmailCell
        email={
          params.row.primary_email ||
          params.row.target_user_email ||
          params.row.user_email
        }
      />
    ),
    valueGetter: (value, row) => row.target_user_email || row.user_email,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 100,
    maxWidth: 130,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    valueGetter: () => {
      return "Pending";
    },
    renderCell: (params: GridRenderCellParams) => (
      <StatusChipCell
        isPending={!!params.row.target_user_email}
        isTest={params.row.is_test}
        label={
          params.row.is_test
            ? "Test " + UserOrganizationRelationshipType.Account
            : UserOrganizationRelationshipType.Account
        }
      />
    ),
  },
];

export default organizationAccountsListColumns;
